import React, { Suspense, lazy, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import ShopAuthLayout from "../layouts/ShopAuthLayout";
import ShopRequireAuth from "../pages/Authorization/ShopAuth/ShopRequireAuth";
import PublicLayout from "../layouts/PublicLayout";
import RequireAuth from "../pages/RequireAuth";
import ErrorBoundary from "./errorBoundary";
import { ROUTE_PRODUCT_PATH } from "../constants/routePath";
import { toolsData } from "../pages/HomePage/Tools/constants/index.js";

// Lazy load components
const HomePage = lazy(() => import("../pages/HomePage"));

// Discover
const Instagram = lazy(() => import("../pages/HomePage/Discover/Instagram"));
const Twitter = lazy(() => import("../pages/HomePage/Discover/Twitter"));
const TikTok = lazy(() => import("../pages/HomePage/Discover/TikTok"));

// Create
const TShirt = lazy(() => import("../pages/HomePage/Create/TShirt"));

const Tools = lazy(() => import("../pages/HomePage/Tools"));

// Auth
const Auth = lazy(() => import("../pages/Auth"));
const Login = lazy(() => import("../pages/Auth/components/Login"));
const Register = lazy(() => import("../pages/Auth/components/Register"));
const ForgotPassword = lazy(() => import("../pages/Auth/components/ForgotPassword"));
const VerifyEmail = lazy(() => import("../pages/Auth/components/Verification"));
const DigitalDownloadPage = lazy(() => import("../pages/DigitalDownloadPage"));

// Admin Pages
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Income = lazy(() => import("../pages/Income"));
const TransactionDetails = lazy(() => import("../pages/Income/TransactionDetails"));
const Customers = lazy(() => import("../pages/Customers"));
const CustomerDetails = lazy(() => import("../pages/Customers/CustomerDetails"));
const MyStore = lazy(() => import("../pages/MyStore"));
const Products = lazy(() => import("../pages/MyStore/components/Products"));
const Settings = lazy(() => import("../pages/Settings"));
const Store = lazy(() => import("../pages/StorePage"));
const Checkout = lazy(() => import("../pages/UserCheckout"));
const DigitalProductSuccess = lazy(() => import("../pages/UserCheckout/DigitalProductSuccess/index.js"));
const EcommerceProductPage = lazy(() => import("../pages/StoreComponents/ProductPage"));
const EcomemrceSuccessPage = lazy(() => import("../pages/StoreComponents/ProductPage/Checkout/Success.js"));
const CancelPage = lazy(() => import("../pages/StoreComponents/ProductPage/Checkout/Cancel.js"));

// Product-related lazy imports
const UrlProduct = lazy(() => import("../pages/MyStore/components/Products/Url"));
const DigitalProduct = lazy(() => import("../pages/MyStore/components/Products/Digital"));
const CoachingCallProduct = lazy(() => import("../pages/MyStore/components/Products/CoachingCall"));
const CustomProduct = lazy(() => import("../pages/MyStore/components/Products/Custom"));
const EmailProduct = lazy(() => import("../pages/MyStore/components/Products/Email"));
const VideoProduct = lazy(() => import("../pages/MyStore/components/Products/Video"));
const ReferralProduct = lazy(() => import("../pages/MyStore/components/Products/Referral"));

// New lazy imports for missing components
const Onboarding = lazy(() => import("../pages/Onboarding"));
const OnboardingSuccess = lazy(() => import("../pages/OnboardingSuccess"));
const Design = lazy(() => import("../pages/MyStore/components/Design"));
const UserProfile = lazy(() => import("../pages/MyStore/components/UserProfile"));
const Billing = lazy(() => import("../pages/Settings/components/Billing"));
const Payments = lazy(() => import("../pages/Settings/components/Payments"));
const Notifications = lazy(() => import("../pages/Settings/components/Notifications"));
const Profile = lazy(() => import("../pages/Settings/components/Profile"));
const Referrals = lazy(() => import("../pages/Referrals"));

//SHOP
const Shop = lazy(() => import("../pages/MyStore/components/Shop")); 
const ShopCategory = lazy(() => import("../pages/MyStore/components/Shop/EcommerceShop/index.js"));
const Collections = lazy(() => import("../pages/MyStore/components/Shop/EcommerceShop/Collections"));
const ProductsCollection = lazy(() => import("../pages/MyStore/components/Shop/EcommerceShop/ProductsCollection")); 
const ShopEcommerceProductPage = lazy(() => import("../pages/MyStore/components/Shop/EcommerceShop/AddProducts/index.js"));

// Configurator
const ClothingConfigurator = lazy(() => import("../pages/HomePage/Create/ClothingConfigurator"));

// Edit product lazy imports
const EditUrlProduct = lazy(() => import("../pages/MyStore/components/Products/Url/editUrl"));
const EditEmailProduct = lazy(() => import("../pages/MyStore/components/Products/Email/editEmail"));
const EditVideoProduct = lazy(() => import("../pages/MyStore/components/Products/Video/editVideo"));
const EditReferralProduct = lazy(() => import("../pages/MyStore/components/Products/Referral/editReferral"));

// ShopDashboard
const ShopDashboard = lazy(() => import("../pages/ShopDashboard"));
const ShopLogin = lazy(() => import("../pages/Authorization/ShopAuth/Auth/components/Login"));
const ShopCollections = lazy(() => import("../pages/ShopDashboard/Collections"));
const ShopProducts = lazy(() => import("../pages/ShopDashboard/Products"));
const ShopOrders = lazy(() => import("../pages/ShopDashboard/Orders"));
const OrderDetails = lazy(() => import("../pages/ShopDashboard/OrderDetails")); 
const ShopViewProducts = lazy(() => import("../pages/ShopDashboard/ProductList/index.js"));

//404
const NotFound = lazy(() => import("../pages/NotFound"));

const currentDomain = window.location.hostname;
//const currentDomain = 'sparkle.store'; // Uncomment for testing

export const ROUTES = (() => {
  switch (currentDomain) {
    case 'admin.sparkle.store':
      return [
        {
          path: '/',
          element: <AuthLayout />,
          children: [
            { index: true, element: <Suspense fallback={null}><Login /></Suspense> },
            { path: 'auth/login', element: <Suspense fallback={null}><Login /></Suspense> },
            { path: 'auth/register', element: <Suspense fallback={null}><Register /></Suspense> },
            { path: 'auth/forgot', element: <Suspense fallback={null}><ForgotPassword /></Suspense> },
            { path: 'auth', element: <Suspense fallback={null}><Auth /></Suspense> },
          ],
        },
        {
          path: '/',
          element: <RequireAuth />,
          children: [
            { path: 'dashboard', element: <Suspense fallback={null}><Dashboard /></Suspense> },
            { path: 'income', element: <Suspense fallback={null}><Income /></Suspense> },
            { path: 'income/:transactionId', element: <Suspense fallback={null}><TransactionDetails /></Suspense> },
            { path: 'customers', element: <Suspense fallback={null}><Customers /></Suspense> },
            { path: 'customer/:customerId', element: <Suspense fallback={null}><CustomerDetails /></Suspense> },
            { path: 'store', element: <Suspense fallback={null}><MyStore /></Suspense> },
            { path: 'products', element: <Suspense fallback={null}><Products /></Suspense> },
            { path: 'settings', element: <Suspense fallback={null}><Settings /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.URL_PRODUCT, element: <Suspense fallback={null}><UrlProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.DIGITAL_PRODUCT, element: <Suspense fallback={null}><DigitalProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.COACHING_CALL, element: <Suspense fallback={null}><CoachingCallProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.CUSTOM_PRODUCT, element: <Suspense fallback={null}><CustomProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EMAIL_PRODUCT, element: <Suspense fallback={null}><EmailProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.VIDEO_PRODUCT, element: <Suspense fallback={null}><VideoProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.REFERRAL_PRODUCT, element: <Suspense fallback={null}><ReferralProduct /></Suspense> },
            { path: 'onboarding', element: <Suspense fallback={null}><Onboarding /></Suspense> },
            { path: 'onboarding-success', element: <Suspense fallback={null}><OnboardingSuccess /></Suspense> },
            { path: 'design', element: <Suspense fallback={null}><Design /></Suspense> },
            { path: 'profile', element: <Suspense fallback={null}><UserProfile /></Suspense> },
            { path: 'settings/billing', element: <Suspense fallback={null}><Billing /></Suspense> },
            { path: 'settings/profile', element: <Suspense fallback={null}><Profile /></Suspense> },
            { path: 'settings/payments', element: <Suspense fallback={null}><Payments /></Suspense> },
            { path: 'settings/notifications', element: <Suspense fallback={null}><Notifications /></Suspense> },
            { path: 'referrals', element: <Suspense fallback={null}><Referrals /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EDIT_URL_PRODUCT, element: <Suspense fallback={null}><EditUrlProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EDIT_EMAIL_PRODUCT, element: <Suspense fallback={null}><EditEmailProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EDIT_VIDEO_PRODUCT, element: <Suspense fallback={null}><EditVideoProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EDIT_REFERRAL_PRODUCT, element: <Suspense fallback={null}><EditReferralProduct /></Suspense> },

            //SHOP COLLECTIONS
            { path: 'shop', element: <Suspense fallback={null}><Shop /></Suspense> },
            { path: 'shop/home', element: <Suspense fallback={null}><ShopCategory /></Suspense> },
            { path: 'shop/:category', element: <Suspense fallback={null}><Collections /></Suspense> },
            { path: 'shop/:category/:collection', element: <Suspense fallback={null}><ProductsCollection /></Suspense> },
            { path: 'shop/:category/:collection/:productSlug', element: <Suspense fallback={null}><ShopEcommerceProductPage /></Suspense> },
          ],
        },
      ];
    case 'shop.sparkle.store':
      return [
        {
          path: '/',
          element: <ShopAuthLayout />,
          children: [
            { index: true, element: <Suspense fallback={null}><ShopLogin /></Suspense> },
            { path: 'auth/login', element: <Suspense fallback={null}><ShopLogin /></Suspense> },
          ],
        },
        {
          path: '/',
          element: <ShopRequireAuth />,
          children: [
            { path: 'store/dashboard', element: <Suspense fallback={null}><ShopDashboard /></Suspense> },
            { path: 'store/collections', element: <Suspense fallback={null}><ShopCollections /></Suspense> },
            { path: 'store/products/:id?', element: <Suspense fallback={null}><ShopProducts /></Suspense> },
            { path: 'view/products', element: <Suspense fallback={null}><ShopViewProducts /></Suspense> },
            { path: 'view/orders', element: <Suspense fallback={null}><ShopOrders /></Suspense> },
            { path: 'view/orders/:id', element: <Suspense fallback={null}><OrderDetails /></Suspense> },
          ],
        },
      ];
    default:
      return [
        {
          path: '/',
          element: <PublicLayout />,
          children: [
            { index: true, element: <Suspense fallback={null}><HomePage /></Suspense> },
            { path: 'tools', element: <Suspense fallback={null}><Tools /></Suspense> },
            ...toolsData.map(tool => ({
              path: `tools/${tool.link.split('/').pop()}`,
              element: <Suspense fallback={null}>{React.createElement(lazy(() => import(`../pages/HomePage/Tools/pages/${tool.file.replace(/\s+/g, '')}`)
              ))}</Suspense>,
            })),
            { path: 'creator/link-in-bio-for-instagram', element: <Suspense fallback={null}><Instagram /></Suspense> },
            { path: 'creator/link-in-bio-for-twitter', element: <Suspense fallback={null}><Twitter /></Suspense> },
            { path: 'creator/link-in-bio-for-tiktok', element: <Suspense fallback={null}><TikTok /></Suspense> },
            { path: 'create/t-shirts', element: <Suspense fallback={null}><TShirt /></Suspense> },
            { path: 'create/t-shirts/customize', element: <Suspense fallback={null}><ClothingConfigurator /></Suspense> },
            { path: ':storeName', element: <Suspense fallback={null}><Store /></Suspense> },
            { path: ':storeName/checkout/:productSlug', element: <Suspense fallback={null}><Checkout /></Suspense> },
            // Digital product download/success page
            { 
              path: ':storeName/checkout/success', 
              element: <Suspense fallback={null}><DigitalProductSuccess /></Suspense> 
            },
            { 
              path: ':storeName/checkout/success/:transactionId', 
              element: <Suspense fallback={null}><DigitalProductSuccess /></Suspense> 
            },
            //Eccomerrce Success and Cancel
            { path: 'checkout/success', element: <Suspense fallback={null}><EcomemrceSuccessPage /></Suspense> },
            { path: 'checkout/cancel', element: <Suspense fallback={null}><CancelPage /></Suspense> },

            { path: ':storeName/product/:productSlug', element: <Suspense fallback={null}><EcommerceProductPage /></Suspense> },
            { path: '*', element: <Suspense fallback={null}><NotFound /></Suspense> },
            {
              path: 'verify-email',
              element: <Suspense fallback={null}><VerifyEmail /></Suspense>
            },
            {
              path: '/digital-download/:token',
              element: <Suspense fallback={null}><DigitalDownloadPage /></Suspense>
            },
          ],
        },
      ];
  }
})();

const router = createBrowserRouter(ROUTES);

export default function App() {
  useEffect(() => {
    // Preload important routes
    Dashboard.preload();
    MyStore.preload();
    Products.preload();
    Store.preload();
  }, []);

  return (
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
}